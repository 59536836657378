import type { BaseResponse } from '../../js/types';

type AjaxFormSenderOptions = {
    onBeforeSend: () => void;
    onSuccess: (response: BaseResponse) => any;
    onError: (err: Error) => any;
    onComplete: () => void;
    data: Record<string, any>;
    headers: Record<string, any>;
    shouldClearInputs?: boolean;
    inputSelector: string;
    method: string;
};

type Send = (url?: string) => Promise<BaseResponse>;

type AppendData = (...data: [string, string | Blob, string?]) => void;

export type AjaxFormSender = {
    send: Send;
    appendData: AppendData;
    clearInputs: () => void;
};

export const clearInputs = (inputs: HTMLInputElement[] = []) => {
    Array.from(inputs).forEach((input) => {
        if (input.type === 'checkbox') {
            input.checked = false;
        } else if (input.type !== 'hidden') {
            input.value = '';
            input.classList.remove('is-not-empty');
        }
        input.dispatchEvent(new Event('blur'));
    });
};

const removeAddedFiles = (form: HTMLElement) => {
    const files = form.querySelectorAll('.js-upload-file');
    Array.from(files).forEach((file) => {
        file.remove();
    });
};

const defaultOptions: AjaxFormSenderOptions = {
    onBeforeSend: () => {},
    onSuccess: () => {},
    onError: () => {},
    onComplete: () => {},
    data: {},
    headers: {},
    shouldClearInputs: true,
    inputSelector: '[name]:not([type="submit"]):not([type="reset"])',
    method: 'post',
};

export default (form: HTMLFormElement, _options: Partial<AjaxFormSenderOptions> = defaultOptions): AjaxFormSender => {
    const options: AjaxFormSenderOptions = { ...defaultOptions, ..._options };
    const method = (form.getAttribute('method') || options.method).toLowerCase();
    const inputs = Array.from(form.querySelectorAll(options.inputSelector)) as HTMLInputElement[];
    let data: FormData | null;
    const preData: Record<string, any> = {};
    const submitBtn = form.querySelector<HTMLInputElement | HTMLButtonElement>(
        'input[type="submit"], button[type="submit"]',
    );
    const isRecaptcha = form.classList.contains('js-ajax-form--recaptcha');

    const appendData: AppendData = (..._data) => {
        const [key, value] = _data;
        preData[key] = value;
    };

    /**
     * Recaptcha
     *
     */

    const recaptchaScript = document.querySelector('.js-recaptcha-script');
    const recaptchaContainer = form.querySelector<HTMLElement>('.js-form-recaptcha');
    let _url: string | null = null;
    let widgetId: string | null = null;
    let isRecaptchaSuccessful = false;

    const actualSend = async (url: string) => {
        options.onBeforeSend();

        if (['post', 'put', 'delete'].includes(method)) {
            data = new FormData(form);

            if (options.data) {
                Object.entries(options.data).forEach((entry) => {
                    data!.append(...entry);
                });
            }

            Object.entries(preData).forEach((entry) => {
                data!.append(...entry);
            });
        }

        form.classList.add('js-ajax-form--loading');

        form.dispatchEvent(new Event('send'));

        if (submitBtn) {
            submitBtn.disabled = true;
        }

        try {
            let response: BaseResponse;

            if (method === 'get') {
                response = await fetch(url, { method }).then((res) => res.json());
            } else {
                response = await fetch(url, {
                    method,
                    body: data,
                    headers: options.headers,
                }).then((res) => res.json());
            }

            if (isRecaptcha && recaptchaContainer) {
                (window as any).grecaptcha.reset(widgetId);
            }

            Array.from(form.querySelectorAll('.app-message')).forEach((messageElement) => {
                messageElement.textContent = '';
            });

            if (response.status === 'success') {
                options.onSuccess(response);
                form.dispatchEvent(new CustomEvent('success', { detail: { data: response } }));
                form.classList.add('js-ajax-form--success');

                if (options.shouldClearInputs) {
                    clearInputs(inputs);
                    removeAddedFiles(form);
                }
            } else {
                throw new Error(response.message || '');
            }

            return response;
        } catch (err) {
            options.onError(err);
            form.dispatchEvent(new CustomEvent('error', { detail: { error: err } }));
            form.classList.remove('js-ajax-form--error');

            throw new Error(err.message || err);
        } finally {
            options.onComplete();
            form.dispatchEvent(new Event('complete'));
            form.classList.remove('js-ajax-form--loading');

            if (submitBtn) {
                submitBtn.disabled = false;
            }
        }
    };

    const renderRecaptcha = () =>
        new Promise((resolve, reject) => {
            if (recaptchaScript) {
                const reCAPTCHA_SITE_KEY_CLIENT = recaptchaScript.getAttribute('data-key');

                if (!reCAPTCHA_SITE_KEY_CLIENT) {
                    reject('reCAPTCHA key not found');
                }

                if (recaptchaContainer) {
                    (window as any).grecaptcha?.ready(() => {
                        const widgetId = (window as any).grecaptcha.render(recaptchaContainer, {
                            sitekey: reCAPTCHA_SITE_KEY_CLIENT,
                            callback: async (response: any) => {
                                isRecaptchaSuccessful = true;

                                if (submitBtn) {
                                    submitBtn.disabled = false;
                                }
                            },
                        });
                        resolve(widgetId);
                    });
                } else {
                    reject('reCAPTCHA container not found');
                }
            } else {
                reject('reCAPTCHA script not found');
            }
        });

    if (isRecaptcha) {
        renderRecaptcha().then((id) => {
            widgetId = id;

            if (submitBtn) {
                submitBtn.disabled = true;
            }
        });
    }

    // form.action - может получить значение инпута с именем action, правильней получать аттрибут
    const send: Send = async (url = form.getAttribute('action') ?? form.action) => {
        if (!(url && typeof url === 'string')) {
            throw new Error('Form does not have "action" attibute and url has not been provided');
        }

        _url = url;

        if (isRecaptcha) {
            if (isRecaptchaSuccessful) {
                actualSend(url);
            }
        } else {
            actualSend(url);
        }
    };

    return { appendData, send, clearInputs: () => clearInputs(inputs) };
};
